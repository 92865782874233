
@import 'npm:bootstrap/dist/css/bootstrap.min.css';
@import 'npm:font-awesome/css/font-awesome.min.css';
@import 'npm:bootstrap-social/bootstrap-social.css';

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #fffeeb;
    color: #363635;
}

div.login {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

div.login a {
    margin-bottom: 10px;
}
